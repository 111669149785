import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const SeeAllNews = () => (
  <Link to="/company/releases">
    <div className="flex items-center">
      <StaticImage src="../../../../images/arrow-left.svg" className="w-4 md:w-8" alt="arrow back" />
      <div className="pl-4 font-bold md:pl-8 text-blue">See all New and Press Releases</div>
    </div>
  </Link>
);

const TrustleAppointsMarcBoroditskyToBoardOfDirectors = () => {
  const releaseTitle = 'Trustle Appoints Marc Boroditsky to Board of Directors';
  return (
    <Layout
      seo={
        <Seo
          title="Newsroom | Trustle Appoints Marc Boroditsky"
          description="Trustle, provider of innovative cloud access management solutions, announced the appointment of Marc
      Boroditsky to its board of directors."
        />
      }
    >
      <section className="pb-16 bg-gradient-to-b from-gray-200 via-white to-gray-200 xl:pb-64">
        <div className="flex flex-col py-16 lg:pb-32 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
          <SectionIntro
            isPageHeader
            hero="Company"
            title="Newsroom"
            description={`Stay up-to-date with everything that is happening with the company.</br>Breaking News and Press releases.`}
          />

          <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
            <div className="py-16">
              <SeeAllNews />
            </div>
            <div className="mx-8">
              <div className="mb-8 text-xl font-bold text-left md:text-2xl">{releaseTitle}</div>
              <p className="text-lg text-left">
                Trustle Appoints Marc Boroditsky to Board of Directors<br></br>
                <br></br> Boroditsky joins existing board members Rick Grinnell and Emiliano Berenbaum, bringing over 30
                years of experience scaling high-performance revenue organizations at global software companies{' '}
                <br></br>
                <br></br>WALNUT CREEK, CA. – June 14, 2023 — Trustle, provider of innovative cloud access management
                solutions, today announced the appointment of Marc Boroditsky to its board of directors. Boroditsky, an
                early advisor and investor of Trustle and who currently serves as President of Revenue at Cloudflare, is
                a technology industry veteran with over 30 years of leadership experience. He joins existing board
                members Rick Grinnell, founder and Managing Partner of Glasswing Ventures and Emiliano Berenbaum,
                co-founder and CEO of Trustle, on the heels of Trustle’s recent $6 million seed funding round to support
                the leadership team in navigating this growth stage.  <br></br>
                <br></br>“Not only are Marc’s roots in building software, but he also has significant experience in all
                stages of a company from pure concept to high growth scaling,” said Berenbaum. “Marc’s first-hand
                knowledge complements Trustle’s business goals and technology roadmap, and I am confident he will play
                an important role in our strategic growth. Trustle is on a mission to revolutionize access-at-risk
                management, and we are thrilled to welcome Marc to our board of directors to further build on that
                mission.” <br></br>
                <br></br>Before Cloudflare, Boroditsky served as Chief Revenue Officer at Twilio Inc., where he played a
                leading role in helping secure the company’s $3 billion in annual revenue and its successful IPO.
                Previously, Marc was Vice President, Identity Management at Oracle, following its 2011 acquisition of
                his startup, Passlogix. As founder and CEO, Boroditsky led Passlogix through the creation of their
                industry-defining approach to simplify and secure access to all computer systems and applications,
                revitalizing the Enterprise Single Sign-On (ESSO) market. Before Passlogix, Boroditsky founded Numera
                Corporation and Novus Technologies, providing technical solutions in the medical and financial fields.
                He is also an active advisor and board member for other impactful technology companies of various
                stages. <br></br>
                <br></br>“In my experience, the generalized approach to identity and access management (IAM) from
                existing vendors has missed the mark when it comes to Dev/Sec/Ops for cloud requirements. Trustle's
                needs-based access control solves these complex requirements," said Boroditsky. "The company has made
                great progress over the past two years and I look forward to continuing the journey as a board member."
                <br></br>
                <br></br>Trustle will be at the 2023 Black Hat conference in Las Vegas from August 8-10. To meet the
                Trustle team and learn more about the company’s access management technology during the event, please
                get in touch. To learn more about Trustle, please visit https://www.trustle.com/.  <br></br>
                About Trustle
                <br></br> Trustle simplifies needs-based access control and enables a more secure and efficient cloud
                environment. With Trustle, system owners can create a comprehensive catalog of permissions and policies
                that allow front-line users to request access or be granted temporary, just-in-time, or until-revoked
                access. Trustle helps developers, citizen developers, teams and organizations manage access-at-risk
                within minutes, establish new strategies and structure to protect business data within days, and improve
                and accelerate compliance. Learn more about Trustle at https://www.trustle.com/.  <br></br>
                <br></br>Media Contact<br></br> Dana Segan<br></br> LaunchTech Communications for Trustle <br></br>
                732-997-9881 <br></br>
                <u>dsegan@golaunchtech.com</u> 
              </p>
            </div>
             
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TrustleAppointsMarcBoroditskyToBoardOfDirectors;
